import React from "react"
import { Container, SubContainer, CategoryTitle } from "./styled"
import { useMocks } from "../../../context"

import Guide from "../guides"
const ListGuides = props => {
  const { homePage } = useMocks()

  const ImmobilierGuide = props.guides.filter((guide, i) => {
    return guide.categories === homePage.categories[0].id
  })
  const EquipementGuide = props.guides.filter((guide, i) => {
    return guide.categories === homePage.categories[1].id
  })

  return (
    <Container isHomePage={props.isHomePage} id={props.id}>
      <div id={homePage.categories[0].id} className="anchor"></div>
      <CategoryTitle>{homePage.categories[0].title}</CategoryTitle>
      <SubContainer>
        {ImmobilierGuide.map((guide, i) => {
          return (
            <Guide
              key={i}
              guide={guide}
              categories={props.categories}
              isHomePage={props.isHomePage}
            />
          )
        })}
      </SubContainer>
      <div id={homePage.categories[1].id} className="anchor"></div>
      <CategoryTitle>{homePage.categories[1].title}</CategoryTitle>
      <SubContainer>
        {EquipementGuide.map((guide, i) => {
          return (
            <Guide
              key={i}
              guide={guide}
              categories={props.categories}
              isHomePage={props.isHomePage}
            />
          )
        })}
      </SubContainer>
    </Container>
  )
}
export default ListGuides
