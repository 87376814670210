import React from "react"
import { Spring } from "react-spring/renderprops.cjs"

export default WrappedComponent => {
  return function wrappedRender(props) {
    return (
      <Spring
        from={{
          opacity: 1,
        }}
        to={{
          opacity: 1,
        }}
      >
        {({ opacity }) => (
          <div style={{ opacity }}>
            <WrappedComponent {...props} />
          </div>
        )}
      </Spring>
    )
  }
}
