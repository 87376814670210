import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Opacity from "../utils/opacity"
import ListGuides from "../components/contentful/listGuides"
import SEO from "../components/seo"
import { useMocks } from "../context"

const Index = props => {
  const { listGuides } = useMocks()
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
    })
  }, [])

  const { allContentfulPurchaseGuide } = props.data

  return (
    <Layout isHomePage>
      <SEO title={listGuides.title} />

      <ListGuides
        guides={allContentfulPurchaseGuide.edges.map(e => e.node)}
        isHomePage
      />
    </Layout>
  )
}

export default Opacity(Index)

export const pagequerypagetemplatebyslug = graphql`
  query indexPage {
    site {
      siteMetadata {
        title
        author
      }
    }
    allContentfulPurchaseGuide {
      edges {
        node {
          slug
          categories
          title
          image {
            description
            fluid(maxWidth: 130) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
