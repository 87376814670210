import styled from "styled-components"
import { StandardBtn } from "../../../styled"

export const Container = styled.div`
  background-color: ${props => props.theme.colors.grey};
  text-align: center;
  padding: ${props => (props.isHomePage ? "0px" : "55px 0 0 0")};
  margin: ${props => (props.isHomePage ? "0px" : "55px 0 ")};

  h1 {
    font-size: 30px;
    margin-bottom: 55px;

    ${props => props.theme.media.mediumPlus`
    padding:0 15px;
    margin-bottom: 25px;
    font-size: 25px;
    line-height:30px;
  `}
  }
  .anchor {
    display: block;
    position: relative;
    top: -190px;
  }
`

export const SubContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;

  ${props => props.theme.media.mediumPlus`

    justify-content:center;
    max-width:unset;
    width:100%;
    overflow:scroll;
  `}
`

export const CategoryTitle = styled.h1`
  text-align: center;
  font-size: 30px;
  margin-top: 110px;
  margin-bottom: 55px;
  font-family: "muli";

  ${props => props.theme.media.mediumPlus`
    margin-top:35px;
    padding:0 15px;
    margin-bottom: 25px;
    font-size: 25px;
    line-height:30px;
  `}
`

export const Cta = styled(StandardBtn)`
  background-color: ${props => props.theme.colors.lightBue};
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 11px;
  padding: 5px 25px;
  font-family: "muli";
`
